//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState, mapMutations } from "vuex";
import VueRecaptcha from "vue-recaptcha";
import apiClientRequest from "@/lib/api";
import rulesHandler from "@/constants/rules";
import getEnv from "@/utils/env";
export default {
  name: "SetKeystorePasswordDialog",
  components: {
    VueRecaptcha
  },
  props: {
    secretType: String,
    secret: String,
    show: Boolean
  },
  data: () => ({
    passwordsValid: false,
    password: "",
    passwordConfirm: "",
    showPassword: false,
    showPasswordConfirm: false,
    passwordConfirmRule: password => val => !!password && password == val || "Passwords must match.",
    recaptchaVerified: false
  }),
  computed: {
    _show: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("toggle", val);
      }
    },
    sitekey() {
      return getEnv("VUE_APP_RECAPTCHA_SITE_KEY");
    },
    ...mapState({
      substrateApi: state => state.substrate.api,
      isLoading: state => state.substrate.isLoadingWallet,
      wallet: state => state.substrate.wallet
    }),
    passwordRules() {
      return [rulesHandler.FIELD_REQUIRED, rulesHandler.PASSWORD];
    }
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    const recaptchaSrc = "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit";
    recaptchaScript.setAttribute("src", recaptchaSrc);
    recaptchaScript.setAttribute("async", true);
    recaptchaScript.setAttribute("defer", true);
    document.head.appendChild(recaptchaScript);
  },
  methods: {
    ...mapActions({
      registerMnemonic: "substrate/registerMnemonic"
    }),
    ...mapMutations({
      setIsLoading: "substrate/SET_LOADING_WALLET"
    }),
    async onVerifyRecaptcha(response) {
      const result = await apiClientRequest.post("/recaptcha", {
        response
      });
      if (result.data.success) this.recaptchaVerified = true;
    },
    async onPasswordSet() {
      try {
        if (this.secretType == "mnemonic") {
          this.setIsLoading(true);
          const result = await this.registerMnemonic({
            mnemonic: this.secret,
            password: this.password
          });
          if (result.success) {
            this._show = false;
            this.$emit("key-store-set");
            this.setIsLoading(false);
            const accounts = Object.keys(window.localStorage).filter(v => /account:/.test(v));
            accounts.forEach(a => {
              const detail = JSON.parse(window.localStorage.getItem(a));
              if (detail.address !== this.wallet.address) {
                window.localStorage.removeItem(a);
              }
            });
            return;
          }
        }
        if (this.secretType == "privateKey") {
          await this.generateWalletFromPrivateKey({
            privateKey: this.secret,
            password: this.password
          });
          this._show = false;
          this.$emit("key-store-set");
          //this.$router.push("/");
          this.setIsLoading(false);
          return;
        }
      } catch (err) {
        this.setIsLoading(false);
        console.log(err);
      }
    },
    closeDialog() {
      this._show = false;
      this.$emit("key-store-set-cancelled");
      this.$refs.passwordForm.reset();
    }
  }
};