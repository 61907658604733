import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm._show,"width":"590","persistent":""}},[_c(VCard,[_c(VAppBar,{attrs:{"flat":"","dense":"","color":"white"}},[_c(VToolbarTitle,{staticClass:"title mt-5 ml-5"},[_vm._v(" Your Secret Backup Phrase ")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"pb-0 text-subtitle-1"},[_c('MnemonicKeypad',{staticClass:"text-center",attrs:{"mnemonicCollection":_vm.mnemonicCollection}}),_c('p',{staticClass:"pl-5 pr-5 text-center"},[_c('b',[_vm._v("Write down these words in the right order and save them safely")])]),_c('p',{staticClass:"mt-10 primary--text",attrs:{"role":"button"},on:{"click":_vm.handleCopyMnemonic}},[_c(VIcon,{staticClass:"primary--text"},[_vm._v("mdi-content-copy")]),_vm._v(" "+_vm._s(_vm.copyText)+" ")],1)],1),_c(VCardActions,{staticClass:"px-6 pb-4"},[_c(VBtn,{attrs:{"depressed":"","color":"primary","large":"","width":"100%"},on:{"click":_vm.onMnemonicSaved}},[_vm._v(" Continue ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }