//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MnemonicKeypad from "./MnemonicKeypad.vue";
import localStorage from "@/lib/local-storage";
const {
  /*cryptoWaitReady,*/mnemonicGenerate
} = require("@polkadot/util-crypto");
let timeout = 0;
export default {
  components: {
    MnemonicKeypad
  },
  name: "SecretBackupPhraseDialog",
  props: {
    show: Boolean,
    role: String
  },
  data: () => ({
    mnemonic: "",
    copyText: "Copy this Mnemonic Phrase to clipboard",
    mnemonicCollection: []
  }),
  computed: {
    _show: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("toggle", val);
      }
    }
  },
  watch: {
    _show(isShow) {
      if (isShow) {
        this.mnemonic = mnemonicGenerate();
        this.mnemonicCollection = [];
        let mnemonicCollection = this.mnemonic.split(" ");
        for (let i = 1; i <= mnemonicCollection.length; i++) {
          this.mnemonicCollection.push(i + " " + mnemonicCollection[i - 1]);
        }
      }
    }
  },
  methods: {
    onMnemonicSaved() {
      this._show = false;
      this.$emit("mnemonic-generated", {
        mnemonic: this.mnemonic,
        role: this.role
      });
      this.mnemonic = "";
    },
    closeDialog() {
      this._show = false;
      this.mnemonic = "";
      const hasAddress = localStorage.getLocalStorageByName("mnemonic_data");
      if (!hasAddress) localStorage.removeLocalStorageByName("TERMS");
    },
    async handleCopyMnemonic() {
      await navigator.clipboard.writeText(this.mnemonic);
      this.copyText = "Mnemonic has been copied successfully !";
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.copyText = "Copy this Mnemonic Phrase to clipboard";
      }, 1000);
    }
  }
};