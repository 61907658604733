import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm._show,"width":"500","persistent":""}},[_c(VCard,[_c(VAppBar,{attrs:{"flat":"","dense":"","color":"white"}},[_c(VToolbarTitle,{staticClass:"title"},[_vm._v(" Backup Your Account ! ")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"pb-0 text-subtitle-1"},[_c('p',[_vm._v(" We will give you 12 words that allows you to recover an account ")]),_c('p',[_c('b',[_vm._v(" You need to carefully save the words. Copy-paste it, screenshot it, write it down, and keep it safe. If you lose it, we won't be able to help you recover it. ")])]),_c(VCheckbox,{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{attrs:{"id":"checkbox"}},[_vm._v(" I understand with DeBio "),_c('a',{attrs:{"target":"_blank","rel":"noreferrer noopener","href":"https://docs.debio.network/legal/privacy-policy"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" Privacy Policy")]),_c('span',[_vm._v(" and")]),_c('a',{attrs:{"target":"_blank","rel":"noreferrer noopener","href":"https://docs.debio.network/legal/terms-and-condition"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" terms and condition")])])]},proxy:true}]),model:{value:(_vm.agreeTerms),callback:function ($$v) {_vm.agreeTerms=$$v},expression:"agreeTerms"}})],1),_c(VCardActions,{staticClass:"px-6 pb-4"},[_c(VBtn,{attrs:{"disabled":!_vm.agreeTerms,"depressed":"","color":"primary","large":"","width":"100%"},on:{"click":_vm.onAgreeTerms}},[_vm._v(" Continue ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }