//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { chunk } from "../lib/arrays";
export default {
  name: "MnemonicKeypad",
  props: {
    mnemonicCollection: Array
  },
  computed: {
    chunkedMnemonic() {
      return chunk(this.mnemonicCollection, 4);
    }
  },
  methods: {
    emitClick(mnemonic) {
      this.$emit("mnemonic-keypad-click", {
        mnemonic: mnemonic
      });
    }
  }
};