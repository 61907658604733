//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import localStorage from "../lib/local-storage";
import { mapActions, mapState, mapMutations } from "vuex";
import { queryBalance } from "@/lib/polkadotProvider/query/balance";
import { fromEther } from "@/lib/balance-format";
import errorMessages from "../../src/constants/error-messages";
export default {
  name: "DialogSelectUserLogin",
  data: () => ({
    keystore: "",
    password: "",
    fileName: "",
    dataAccount: null,
    dataAccountJson: "",
    balance: 0,
    nonce: null,
    keystoreInputErrors: [],
    loading: false,
    dataMnemonicJson: null
  }),
  props: {
    show: Boolean
  },
  computed: {
    _show: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("toggle", val);
      }
    },
    ...mapState({
      isLoading: state => state.substrate.isLoadingWallet,
      api: state => state.substrate.api
    })
  },
  async mounted() {
    await this.getKeyStoreLocal();
  },
  methods: {
    ...mapActions({
      restoreAccountKeystore: "substrate/restoreAccountKeystore"
    }),
    ...mapMutations({
      setIsLoading: "substrate/SET_LOADING_WALLET"
    }),
    async getKeyStoreLocal() {
      try {
        var _this$dataAccount;
        this.dataAccountJson = localStorage.getKeystore();
        this.dataAccount = JSON.parse(this.dataAccountJson);
        if (!this.dataAccount) return;
        this.loading = true;
        const balance = await queryBalance(this.api, (_this$dataAccount = this.dataAccount) === null || _this$dataAccount === void 0 ? void 0 : _this$dataAccount.address);
        this.balance = Number(await fromEther(balance, "DBIO")).toFixed(3);
        this.loading = false;
      } catch (err) {
        console.error(err);
        this.loading = false;
      }
    },
    setKeystore() {
      this.keystore = this.dataAccountJson;
      this.fileName = "keystore";
    },
    setKeystoreFileInputListener() {
      const context = this;
      this.$refs.keystoreFileInput.addEventListener("change", function () {
        const file = this.files[0];
        context.fileName = file.name;
        const fr = new FileReader();
        fr.onload = async function () {
          // TODO: Validate if valid keystore
          context.keystore = fr.result;
        };
        fr.readAsText(file);
      });
    },
    saveKeystoreToLocalStorage(keystore) {
      localStorage.setKeystore(keystore);
    },
    closeDialog() {
      this._show = false;
      this.clearInput();
    },
    clearInput() {
      this.keystore = "", this.password = "", this.fileName = "", this.keystoreInputErrors = [];
    },
    async onPasswordInput() {
      this.loading = true;
      this.setIsLoading(true);
      this.keystoreInputErrors = [];
      let dataKeystore = [];
      const keystore = JSON.parse(this.keystore);
      if (keystore != null) {
        if (Array.isArray(keystore)) {
          dataKeystore = keystore;
        } else {
          dataKeystore.push(keystore);
          if (this.dataMnemonicJson != null && this.dataMnemonicJson != "") {
            dataKeystore.push(JSON.parse(this.dataMnemonicJson));
          }
        }
        const result = await this.restoreAccountKeystore({
          file: dataKeystore,
          password: this.password
        });
        this.loading = false;
        this.setIsLoading(false);
        this.keystoreInputErrors = "";
        if (result.success) {
          this._show = false;
          this.clearInput();
          const account = Object.keys(window.localStorage).find(v => /account:/.test(v));
          localStorage.setKeystore(localStorage.getLocalStorageByName(account));
          this.$emit("key-store-set");
          return;
        } else {
          this.keystoreInputErrors = errorMessages.INCORRECT("password");
        }
      } else {
        this.setIsLoading(false);
        this.keystoreInputErrors = "no keystore";
      }
    },
    showMnemonicForm() {
      this.$emit("show-mnemonic-form");
    }
  }
};