//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { chunk } from "../lib/arrays";
export default {
  name: "MnemonicKeypad",
  props: {
    mnemonicCollection: Array
  },
  computed: {
    chunkedMnemonic() {
      let collection = this.mnemonicCollection;
      if (collection.length < 12) {
        let diff = 12 - collection.length;
        for (let i = 0; i < diff; i++) {
          collection.push(" ");
        }
      }
      return chunk(collection, 4);
    }
  },
  methods: {
    emitClick(idx, mnemonic) {
      this.$emit("mnemonic-index-remove", {
        idx: idx,
        mnemonic
      });
    }
  }
};