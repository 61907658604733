import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm._show,"width":"500","persistent":""}},[_c(VCard,[_c(VAppBar,{attrs:{"flat":"","dense":"","color":"white"}},[(_vm.type == 'login-mnemonic')?_c(VToolbarTitle,{staticClass:"title"},[_vm._v(" Login Mnemonic ")]):_c(VToolbarTitle,{staticClass:"title"},[_vm._v(" Forgot Password ")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c('hr'),_c(VCardText,{staticClass:"mt-4 pb-0 text-subtitle-1"},[_c(VForm,{ref:"form",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c(VTextarea,{attrs:{"outlined":"","auto-grow":"","label":"Type in your mnemonic phrase.","rules":_vm.mnemonicRules},model:{value:(_vm.mnemonic),callback:function ($$v) {_vm.mnemonic=$$v},expression:"mnemonic"}})],1)],1),_c(VCardActions,{staticClass:"px-6 pb-4"},[_c(VBtn,{attrs:{"depressed":"","color":"primary","large":"","width":"100%","disabled":!_vm.formValid},on:{"click":_vm.onContinue}},[_vm._v(" Submit ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }