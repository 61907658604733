//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import localStorage from "@/lib/local-storage";
export default {
  name: "GenerateAccountDialog",
  props: {
    show: Boolean,
    role: String
  },
  data: () => ({
    agreeTerms: false
  }),
  computed: {
    _show: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("toggle", val);
      }
    }
  },
  created() {
    const hasTerms = localStorage.getLocalStorageByName("TERMS");
    if (hasTerms) this.agreeTerms = hasTerms;
  },
  methods: {
    onAgreeTerms() {
      this._show = false;
      this.$emit("terms-agreed");
      localStorage.setLocalStorageByName("TERMS", this.agreeTerms);
      const hasAddress = localStorage.getLocalStorageByName("mnemonic_data");
      if (!hasAddress) {
        localStorage.removeLocalStorageByName("TERMS");
        this.agreeTerms = false;
      }
    },
    closeDialog() {
      this._show = false;
      this.agreeTerms = false;
    }
  }
};