//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mnemonicValidate } from "@polkadot/util-crypto";
export default {
  name: "AccessAccountMnemonicDialog",
  props: {
    show: Boolean,
    role: String,
    type: String
  },
  data: () => ({
    formValid: true,
    mnemonic: ""
  }),
  computed: {
    _show: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("toggle", val);
      }
    },
    mnemonicRules() {
      return [val => !!val || "Mnemonic cannot be empty.", val => val && mnemonicValidate(val) || "The mnemonic you entered is incorrect.", val => val && val.trim().split(/\s+/g).length >= 12 || "Number of words must be 12 or more"];
    }
  },
  methods: {
    onContinue() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this._show = false;
      if (this.type == "login-mnemonic") {
        this.$emit("mnemonic-input-login", {
          mnemonic: this.mnemonic,
          role: this.role
        });
      } else {
        this.$emit("mnemonic-input", {
          mnemonic: this.mnemonic,
          role: this.role
        });
      }
      this.$refs.form.reset();
    },
    closeDialog() {
      this._show = false;
      this.$refs.form.reset();
    }
  }
};