//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapActions } from "vuex";
//import DevMenu from "@/components/DevMenu";
//import SettingsMenu from "@/components/SettingsMenu";
import Button from "@/components/Button";
import DialogSelectUserLogin from "@/components/DialogSelectUserLogin";
import GenerateAccountDialog from "@/components/GenerateAccountDialog";
import SecretBackupPhraseDialog from "@/components/SecretBackupPhraseDialog";
import SetKeystorePasswordDialog from "@/components/SetKeystorePasswordDialog";
import VerifyRecoveryPhraseDialog from "@/components/VerifyRecoveryPhraseDialog";
import AccessAccountMnemonicDialog from "@/components/AccessAccountMnemonicDialog";
import DialogAlert from "@/components/Dialog/DialogAlert";
import getEnv from "@/utils/env";
export default {
  name: "Home",
  components: {
    Button,
    //DevMenu,
    //SettingsMenu,
    DialogSelectUserLogin,
    GenerateAccountDialog,
    SecretBackupPhraseDialog,
    SetKeystorePasswordDialog,
    VerifyRecoveryPhraseDialog,
    AccessAccountMnemonicDialog,
    DialogAlert
  },
  computed: {
    isDevEnv() {
      return getEnv("NODE_ENV") == "development";
    }
  },
  data: () => ({
    role: "",
    secret: "",
    mnemonic: "",
    secretType: "",
    dialogSelectUserLogin: false,
    generateAccountDialog: false,
    secretBackupPhraseDialog: false,
    setKeystorePasswordDialog: false,
    verifyRecoveryPhraseDialog: false,
    accessAccountMnemonicDialog: false,
    loginUsingMnemonicDialog: false,
    forgotAddress: "",
    dialogAlert: false,
    alertTextBtn: "",
    alertImgPath: "warning.png",
    alertTextAlert: "",
    imgWidth: "50",
    successLogin: false,
    typelogin: ""
  }),
  created() {
    if (this.$route.query.forgot) {
      this.loginUsingMnemonicDialog = true;
    }
  },
  methods: {
    ...mapActions({
      checkMnemonicSomeAddress: "substrate/checkMnemonicSomeAddress"
    }),
    ...mapMutations({}),
    onGenerateAccount() {
      this.generateAccountDialog = true;
      this.typelogin = "register";
    },
    onUseMnemonic(status, address) {
      if (status) {
        this.forgotAddress = address;
        this.accessAccountMnemonicDialog = true;
      }
    },
    showMnemonicForm() {
      this.loginUsingMnemonicDialog = true;
    },
    openSignIn() {
      this.dialogSelectUserLogin = true;
      this.typelogin = "login";
    },
    showSecretBackupPhraseDialog() {
      this.secretBackupPhraseDialog = true;
    },
    showVerifyRecoveryPhraseDialog(mnemonic, role) {
      this.mnemonic = mnemonic;
      this.role = role;
      this.verifyRecoveryPhraseDialog = true;
    },
    showSetKeystorePasswordDialog(mnemonic, role) {
      this.secret = mnemonic;
      this.secretType = "mnemonic";
      this.role = role;
      this.setKeystorePasswordDialog = true;
    },
    async checkForgotPassword(previousDialog, secretType, secret) {
      const result = await this.checkMnemonicSomeAddress({
        mnemonic: secret.mnemonic,
        accountAddress: this.forgotAddress
      });
      if (result.success) {
        this.setKeyStorePassword(previousDialog, secretType, secret);
      } else {
        this.alertTextBtn = "OKE";
        this.alertImgPath = "warning.png";
        this.alertTextAlert = "Supplied mnemonic phrase and account password does not match any existing accounts.";
        this.dialogAlert = true;
      }
    },
    setKeyStorePassword(previousDialog, secretType, secret) {
      this.secretType = secretType; // mnemonic or privateKey
      this.secret = secret.mnemonic; // mnemonic or privateKey string
      this[previousDialog] = false; // Hide previous dialog
      this.setKeystorePasswordDialog = true;
    },
    clearSecret() {
      this.secretType = "";
      this.secret = "";
      this.successLogin = false;
      this.typelogin = "";
    },
    async actionAlert() {
      if (this.successLogin) {
        this.clearSecret();
        await this.$store.dispatch("substrate/connect");
        await this.$store.dispatch("substrate/getLabAccount");
        this.$router.push("/");
      } else {
        this.clearSecret();
      }
    },
    successLoginAction() {
      const {
        redirect,
        ...payload
      } = this.$route.query;
      this.successLogin = true;
      this.alertTextBtn = "Continue";
      this.alertImgPath = "success.png";
      if (this.typelogin == "login") {
        this.alertTextAlert = "Login successful, welcome back!";
      } else {
        this.alertTextAlert = "Account registration successful!";
      }
      this.dialogAlert = true;
      if (redirect) this.$router.push({
        name: redirect,
        query: {
          ...payload
        }
      });
    }
  }
};