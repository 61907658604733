//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from "@/components/Button";
export default {
  name: "DialogAlert",
  components: {
    Button
  },
  props: {
    show: Boolean,
    titleAlert: String,
    textAlert: String,
    imgPath: String,
    btnText: String,
    imgWidth: String
  },
  data: () => ({}),
  computed: {
    _show: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("toggle", val);
      }
    }
  },
  methods: {
    closeDialog() {
      this._show = false;
      this.$emit("close");
    }
  }
};