//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MnemonicTextbox from "./MnemonicTextbox.vue";
import MnemonicKeypad from "./MnemonicKeypad.vue";
import { shuffle, removeArrayByIndex, isIdentical } from "../lib/arrays";
import errorMessages from "../../src/constants/error-messages";
export default {
  components: {
    MnemonicKeypad,
    MnemonicTextbox
  },
  name: "VerifyRecoveryPhraseDialog",
  props: {
    show: Boolean,
    role: String,
    mnemonic: String
  },
  data: () => ({
    mnemonicData: "",
    shuffledMnemonic: [],
    correctMnemonicCollection: [],
    mnemonicCollection: [],
    mnemonicCollectionTextbox: [],
    shouldBeDisabled: true,
    errorMessages
  }),
  computed: {
    _show: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("toggle", val);
      }
    },
    mnemonicError() {
      if (!isIdentical(this.mnemonicCollection, this.correctMnemonicCollection) && this.mnemonicCollection.length === 12) {
        return true;
      }
      return false;
    }
  },
  watch: {
    _show(isShow) {
      if (isShow) {
        this.mnemonicData = this.mnemonic;
        this.correctMnemonicCollection = this.mnemonicData.split(" ");
        this.shuffledMnemonic = shuffle(this.mnemonicData.split(" "));
      }
    }
  },
  methods: {
    removeMnemonicCollectionTextbox(idx, mnemonic) {
      if (mnemonic.trim() !== "") this.shuffledMnemonic.push(mnemonic);
      this.mnemonicCollection = removeArrayByIndex(this.mnemonicCollection, idx);
      this.mnemonicCollectionTextbox = [];
      for (let i = 0; i < this.mnemonicCollection.length; i++) {
        this.mnemonicCollectionTextbox.push(this.mnemonicCollection[i]);
      }
    },
    addMnemonicCollectionTextbox(mnemonic) {
      const index = this.shuffledMnemonic.indexOf(mnemonic);
      this.shuffledMnemonic.splice(index, 1);
      if (this.mnemonicCollection.length < 12) {
        this.mnemonicCollection.push(mnemonic);
        this.mnemonicCollectionTextbox = [];
        for (let i = 0; i < this.mnemonicCollection.length; i++) {
          this.mnemonicCollectionTextbox.push(this.mnemonicCollection[i]);
        }
        this.shouldBeDisabled = !isIdentical(this.mnemonicCollection, this.correctMnemonicCollection);
      }
    },
    onMnemonicSaved() {
      this._show = false;
      this.$emit("mnemonic-and-role", {
        mnemonic: this.mnemonicData,
        role: this.role
      });
      this.mnemonicData = "";
      this.shuffledMnemonic = [];
      this.mnemonicCollection = [];
      this.mnemonicCollectionTextbox = [];
      this.correctMnemonicCollection = [];
    },
    closeDialog() {
      this._show = false;
      this.mnemonicData = "";
      this.shuffledMnemonic = [];
      this.mnemonicCollection = [];
      this.mnemonicCollectionTextbox = [];
      this.correctMnemonicCollection = [];
    }
  }
};