import { VApp } from 'vuetify/lib/components/VApp';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c(VMain,{staticClass:"login-main"},[_c(VContainer,{attrs:{"fill-height":""}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c(VRow,{staticClass:"justify-center"},[_c(VCol,{attrs:{"cols":"12","lg":"5","md":"5","sm":"5","align":"center"}},[_c(VImg,{attrs:{"src":require("@/assets/debio-logo-words-grey.png")}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"12","md":"12","sm":"12","align":"center"}},[_c('div',{staticClass:"white--text text-lg-h6 mt-3"},[_vm._v(" The Privacy-First Platform for Personal Genetic Testing ")])])],1),_c(VRow,{staticClass:"justify-center mt-10 align-center"},[_c(VCol,{attrs:{"cols":"12","lg":"3","md":"5","sm":"8","align":"center"}},[_c('Button',{attrs:{"color":"white","elevation":"2","dark":""},on:{"click":_vm.onGenerateAccount}},[_c('div',{staticClass:"primary--text"},[_vm._v("Create Account")])])],1),_c(VCol,{attrs:{"cols":"12","lg":"3","md":"5","sm":"8","align":"center"}},[_c('Button',{attrs:{"elevation":"2","dark":""},on:{"click":_vm.openSignIn}},[_vm._v(" Sign In ")])],1)],1)],1)],1)],1),_c('DialogSelectUserLogin',{attrs:{"show":_vm.dialogSelectUserLogin},on:{"toggle":function($event){_vm.dialogSelectUserLogin = $event},"forgot-password":function (ref) {
          var status = ref.status;
          var address = ref.address;

          return _vm.onUseMnemonic(status, address);
},"key-store-set":_vm.successLoginAction,"show-mnemonic-form":_vm.showMnemonicForm}}),_c('GenerateAccountDialog',{attrs:{"show":_vm.generateAccountDialog},on:{"toggle":function($event){_vm.generateAccountDialog = $event},"terms-agreed":function($event){return _vm.showSecretBackupPhraseDialog()}}}),_c('SecretBackupPhraseDialog',{attrs:{"show":_vm.secretBackupPhraseDialog,"role":_vm.role},on:{"toggle":function($event){_vm.secretBackupPhraseDialog = $event},"mnemonic-generated":function (ref) {
          var mnemonic = ref.mnemonic;
          var role = ref.role;

          return _vm.showVerifyRecoveryPhraseDialog(mnemonic, role);
}}}),_c('VerifyRecoveryPhraseDialog',{attrs:{"show":_vm.verifyRecoveryPhraseDialog,"role":_vm.role,"mnemonic":_vm.mnemonic},on:{"toggle":function($event){_vm.verifyRecoveryPhraseDialog = $event},"mnemonic-and-role":function (ref) {
          var mnemonic = ref.mnemonic;
          var role = ref.role;

          return _vm.showSetKeystorePasswordDialog(mnemonic, role);
}}}),_c('SetKeystorePasswordDialog',{attrs:{"secretType":_vm.secretType,"secret":_vm.secret,"show":_vm.setKeystorePasswordDialog},on:{"toggle":function($event){_vm.setKeystorePasswordDialog = $event},"key-store-set":_vm.successLoginAction,"key-store-set-cancelled":_vm.clearSecret}}),_c('AccessAccountMnemonicDialog',{attrs:{"show":_vm.accessAccountMnemonicDialog,"type":'forgot-password'},on:{"toggle":function($event){_vm.accessAccountMnemonicDialog = $event},"mnemonic-input":function (mnemonic) { return _vm.checkForgotPassword(
            'accessAccountMnemonicDialog',
            'mnemonic',
            mnemonic
          ); }}}),_c('AccessAccountMnemonicDialog',{attrs:{"show":_vm.loginUsingMnemonicDialog,"type":'login-mnemonic'},on:{"toggle":function($event){_vm.loginUsingMnemonicDialog = $event},"mnemonic-input-login":function (ref) {
                    var mnemonic = ref.mnemonic;

                    return _vm.showSetKeystorePasswordDialog(mnemonic);
}}}),_c('DialogAlert',{attrs:{"show":_vm.dialogAlert,"btnText":_vm.alertTextBtn,"textAlert":_vm.alertTextAlert,"imgPath":_vm.alertImgPath,"imgWidth":_vm.imgWidth},on:{"toggle":function($event){_vm.dialogAlert = $event},"close":function($event){return _vm.actionAlert()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }