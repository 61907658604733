import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm._show,"width":"590","persistent":""}},[_c(VCard,[_c(VAppBar,{attrs:{"flat":"","dense":"","color":"white"}},[_c(VContainer,{staticClass:"title mt-5 mb-5 text-center"},[_vm._v(" Verify Recovery Phrase ")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"pb-0 text-subtitle-1 text-center"},[_c('b',[_vm._v("Tap the words to put them next to each other in the correct order")]),_c('MnemonicTextbox',{attrs:{"mnemonicCollection":_vm.mnemonicCollectionTextbox},on:{"mnemonic-index-remove":function (ref) {
	var idx = ref.idx;
	var mnemonic = ref.mnemonic;

	return _vm.removeMnemonicCollectionTextbox(idx, mnemonic);
}}}),(_vm.mnemonicError)?_c('span',{staticStyle:{"color":"#F92020"}},[_vm._v(_vm._s(_vm.errorMessages.INCORRECT("mnemonic")))]):_vm._e(),_c('MnemonicKeypad',{attrs:{"mnemonicCollection":_vm.shuffledMnemonic},on:{"mnemonic-keypad-click":function (ref) {
	var mnemonic = ref.mnemonic;

	return _vm.addMnemonicCollectionTextbox(mnemonic);
}}})],1),_c(VCardActions,{staticClass:"px-6 pb-4"},[_c(VBtn,{attrs:{"disabled":_vm.shouldBeDisabled,"depressed":"","color":"primary","large":"","width":"100%"},on:{"click":_vm.onMnemonicSaved}},[_vm._v(" Continue ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }