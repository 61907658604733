import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm._show,"max-width":"500","persistent":""}},[_c(VCard,[_c(VAppBar,{attrs:{"flat":"","dense":"","color":"white"}},[_c(VToolbarTitle,{staticClass:"title"},[_vm._v(" Sign In")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c('hr'),(!_vm.loading)?_c('div',[(_vm.keystore)?[_c(VCardText,{staticClass:"mt-4 px-6 pb-0 text-subtitle-1"},[_c(VForm,{ref:"form"},[_c('div',{staticStyle:{"font-size":"12px"}},[_vm._v("Please enter your password")]),(_vm.fileName)?_c(VTextField,{attrs:{"outlined":"","auto-grow":"","type":"password","error-messages":_vm.keystoreInputErrors,"disabled":_vm.isLoading},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onPasswordInput($event)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}):_vm._e()],1),(_vm.isLoading)?_c(VProgressLinear,{attrs:{"indeterminate":"","color":"red"}}):_vm._e()],1),_c(VCardActions,{staticClass:"px-6 pb-4"},[_c(VBtn,{attrs:{"depressed":"","color":"primary","large":"","width":"100%","disabled":!_vm.password},on:{"click":_vm.onPasswordInput}},[(_vm.isLoading)?_c(VProgressCircular,{attrs:{"size":20,"color":"white","indeterminate":""}}):_c('div',[_vm._v("Submit")])],1)],1)]:_vm._e(),(_vm.dataAccount != null && _vm.keystore == '')?[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"mt-3 mb-10",class:{ 'card-hover': hover },attrs:{"elevation":"0"},on:{"click":_vm.setKeystore}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"my-5 ml-5"},[_c(VImg,{attrs:{"src":require("@/assets/user-profile-sample.png"),"max-width":"48"}})],1),_c('div',[_c('div',{staticClass:"ml-5 text-left",class:hover ? 'primary--text' : ''},[_vm._v(" "+_vm._s(_vm.dataAccount.meta.name)+" ")]),_c('div',{staticClass:"ml-5 primary--text",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.balance)+" ")]),_c('div',{staticClass:"ml-5 grey--text",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.dataAccount.address)+" ")])])])])]}}],null,false,3701721280)})]:(_vm.keystore == '')?[_c('div',{staticClass:"d-flex justify-center mb-5 mt-5"},[_c(VImg,{attrs:{"src":require("@/assets/warning.png"),"max-width":"48"}})],1),_c('div',{staticClass:"mb-5",attrs:{"align":"center"}},[_vm._v("No accounts found.")])]:_vm._e(),(_vm.keystore == '')?[_c(VCardText,{staticClass:"pb-0 text-subtitle-1"},[_c('input',{ref:"keystoreFileInput",staticStyle:{"display":"none"},attrs:{"type":"file"}}),_c('div',{staticStyle:{"width":"100%"}},[_c(VBtn,{attrs:{"depressed":"","color":"primary","medium":"","width":"100%"},on:{"click":_vm.showMnemonicForm}},[_vm._v(" Input Mnemonic ")])],1)]),_c(VCardActions,{staticClass:"px-6 pb-4"})]:_vm._e()],2):_c('div',{staticClass:"d-flex justify-center mt-10 pb-10"},[_c(VProgressCircular,{attrs:{"size":50,"color":"primary","indeterminate":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }